import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { ResidentialPropertyForSaleListing } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { DEFAULT_LOCALE } from '@unreserved-frontend-v2/utils/locale'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'
import { ListingHighlight } from '../listing-highlight/listing-highlight'
import { ListingHighlightDifferentSalePrice } from '../listing-highlight/variants/different-sale-price'
import { RegisterOrSignInPrompt } from '../../../users/components/register-or-sign-in/register-or-sign-in-prompt'
import { getParsedPriceValues } from '../../utils/formatters/get-parsed-price-values'
import { Pill } from '@unreserved-frontend-v2/ui/pill'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface ListingsStackCardProps {
  testid: string
  title: string
  subTitle: string
  label: string
  count?: number
  href: string
  listings: ResidentialPropertyForSaleListing[]
  containerClassName?: string
  isRestricted?: true | undefined
}

export const ListingsStackCard = ({
  title,
  subTitle,
  label,
  count,
  href,
  listings,
  containerClassName,
  isRestricted,
  testid,
}: ListingsStackCardProps) => {
  return (
    <div className={twMerge('relative h-[408px] rounded bg-white px-6 pt-4', containerClassName)}>
      <Link href={href} data-testid={testid}>
        <h2 className="pb-2 text-xl font-bold leading-[20px]">{title}</h2>
        {subTitle}
        <div className="absolute right-6 top-4 inline-flex items-center">
          <Pill>{count?.toLocaleString(DEFAULT_LOCALE)}</Pill>
          <FontAwesomeIcon className="pl-4" icon={faArrowRight} transform={{ size: 20 }} />
        </div>
      </Link>
      <hr className="text-shades-200 my-3.5" />
      <h3 className={twMerge('font-bold', isRestricted ? 'select-none opacity-50 blur-sm' : null)}>{label}</h3>
      <div
        className={twMerge(
          'divide-shades-200 flex flex-col divide-y',
          isRestricted ? 'select-none opacity-50 blur-sm' : null
        )}
      >
        {listings?.map((listing) => {
          const { isSaleDifferentFromList } = getParsedPriceValues(listing, isRestricted)
          const Component = !isSaleDifferentFromList ? ListingHighlight : ListingHighlightDifferentSalePrice

          return <Component key={listing.id} containerClassName="pt-5 pb-4 first:pt-4 last:pb-0" listing={listing} />
        })}
      </div>
      {isRestricted ? <RegisterOrSignInPrompt className="text-shades-600 top-[242px] w-[300px]" isSold={true} /> : null}
    </div>
  )
}

export default ListingsStackCard
