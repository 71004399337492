import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { twMerge } from 'tailwind-merge'

import { Aggregation } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { Pill } from '@unreserved-frontend-v2/ui/pill'
import { Skeleton } from '@unreserved-frontend-v2/ui/skeleton/skeleton'
import { TileLink } from '@unreserved-frontend-v2/ui/tile-link/tile-link'
import { currency, formatCash } from '@unreserved-frontend-v2/utils/currency'
import { DEFAULT_LOCALE } from '@unreserved-frontend-v2/utils/locale'

import { DEFAULT_LISTING_DIRECTORY, DEFAULT_URL_PLACE_PREFIX } from '../../utils/routing/constants'

export interface SearchByPlaceProps {
  parentPlaceType: string
  selectedParentSlug?: string
  parentPlaces?: Aggregation[] | null
  childPlaces?: Aggregation[] | null
  isFetching?: boolean
}

const SKELETON_TILES_COUNT = 35

export const SearchByPlace = ({
  parentPlaceType,
  parentPlaces,
  childPlaces,
  selectedParentSlug = '',
  isFetching,
}: SearchByPlaceProps) => {
  const { t } = useTranslation()
  const { pathname } = useRouter()
  const selectedParentSlugAggregation = parentPlaces?.find((place) => place.id === selectedParentSlug)
  const translationKeyPrefix = `listings:browse-by.${parentPlaceType}`

  return (
    <div className="flex items-start gap-x-6 overflow-hidden rounded border border-shades-200 bg-shades-25">
      <div className="w-[266px] flex-shrink-0">
        {parentPlaces?.map((place) => (
          <Link
            key={place.id}
            data-testid={place.id}
            href={`${pathname}?${parentPlaceType}=${place.id}`}
            shallow={true}
            scroll={false}
            className={twMerge(
              'relative block border-r border-b border-shades-200 p-4 last:top-[1px] last:-mt-[1px]',
              selectedParentSlug === place.id ? 'border-r-transparent bg-shades-25' : 'bg-white'
            )}
          >
            <div className="flex">
              <div className="min-width-[0px] flex-grow truncate pr-2 pb-0.5 font-bold">{place.name}</div>
              <Pill>{place.count.toLocaleString(DEFAULT_LOCALE)}</Pill>
            </div>
            <div>
              {currency(place.value)} {t('listings:browse-by.median-price')}
            </div>
          </Link>
        ))}
      </div>
      <div className="grid flex-grow grid-cols-10 items-start gap-3 py-6 pr-6">
        {isFetching ? (
          // Skeleton tiles
          Array.from({ length: SKELETON_TILES_COUNT }, (_, i) => <Skeleton key={i} className="col-span-2 h-[103px]" />)
        ) : (
          <>
            {selectedParentSlugAggregation ? (
              <TileLink
                id={selectedParentSlugAggregation.id}
                name={t(`${translationKeyPrefix}.all`)}
                href={`${DEFAULT_LISTING_DIRECTORY}/${DEFAULT_URL_PLACE_PREFIX}-${selectedParentSlugAggregation.id}`}
                containerClassname="col-span-2 truncate"
              >
                {selectedParentSlugAggregation.count.toLocaleString(DEFAULT_LOCALE)} {t('listings:browse-by.listings')}
                <br />${formatCash(selectedParentSlugAggregation.value, true)} {t('listings:browse-by.median-price')}
              </TileLink>
            ) : null}
            {childPlaces?.map((place) => (
              <TileLink
                data-testid={place.id}
                key={place.id}
                id={place.id}
                href={`${DEFAULT_LISTING_DIRECTORY}/${DEFAULT_URL_PLACE_PREFIX}-${place.id}`}
                name={place.name || ''}
                containerClassname="col-span-2 truncate"
              >
                {place.count.toLocaleString(DEFAULT_LOCALE)} {t('listings:browse-by.listings')}
                <br />${formatCash(place.value, true)} {t('listings:browse-by.median-price')}
              </TileLink>
            ))}
          </>
        )}
      </div>
    </div>
  )
}
