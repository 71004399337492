import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface DesktopWidthProps {
  children: ReactNode
  className?: string
}

export const DESKTOP_WIDTH_CLASSES = 'min-w-5xl mx-auto max-w-7xl px-5'

export function DesktopWidth({
  children,
  className = '',
  ...rest
}: DesktopWidthProps & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={twMerge(DESKTOP_WIDTH_CLASSES, className)} {...rest}>
      {children}
    </div>
  )
}
