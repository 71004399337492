import {
  PropertyListingStatus,
  ResidentialPropertyForSaleListing,
} from '@unreserved-frontend-v2/api/generated/graphql/types'
import { currency } from '@unreserved-frontend-v2/utils/currency'

export const getParsedPriceValues = (listing: ResidentialPropertyForSaleListing, isRestricted: true | undefined) => {
  const listPrice = currency(listing?.listingPrice?.amount)

  const isSaleDifferentFromList =
    listing?.listingStatus === PropertyListingStatus.Sold &&
    !isRestricted &&
    listing?.salePrice?.amount !== listing?.listingPrice?.amount

  const price = isSaleDifferentFromList ? currency(listing?.salePrice?.amount) : listPrice

  return {
    listPrice,
    price,
    isSaleDifferentFromList,
  }
}
