import { ADDRESS_1_GRID_AREA } from '../components/address-line-1/address-line-1'
import { ADDRESS_CAPTION_GRID_AREA } from '../components/address-caption/address-caption'
import { PRICE_GRID_AREA } from '../components/price/price'
import { PROPERTY_DETAILS_GRID_AREA } from '../components/property-details/property-details'
import { THUMBNAIL_IMAGE_GRID_AREA } from '../components/thumbnail-image/thumbnail-image'
import ListingHighlight, { ListingHighlightProps } from '../listing-highlight'

const style = {
  gridTemplateAreas: `"${THUMBNAIL_IMAGE_GRID_AREA} ${ADDRESS_1_GRID_AREA} ${PRICE_GRID_AREA}"
                      "${THUMBNAIL_IMAGE_GRID_AREA} ${ADDRESS_CAPTION_GRID_AREA} ${PRICE_GRID_AREA}"
                      "${THUMBNAIL_IMAGE_GRID_AREA} ${PROPERTY_DETAILS_GRID_AREA} ${PROPERTY_DETAILS_GRID_AREA}"
                     `,
}

/**
 * Variant of ListingHighlight that shows a crossed out salePrice
 */
export function ListingHighlightDifferentSalePrice({ listing, containerClassName }: ListingHighlightProps) {
  return <ListingHighlight containerClassName={containerClassName} style={style} listing={listing} />
}

export default ListingHighlightDifferentSalePrice
