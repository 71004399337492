import { useMemo } from 'react'
import { GraphQLClient } from 'graphql-request'
import { ClientError } from 'graphql-request'
import { UseQueryOptions } from '@tanstack/react-query'

import { API_ENDPOINT } from '../constants'
import {
  GetHomeBrowseByPlacesQuery,
  GetHomeBrowseByPlacesQueryVariables,
  useGetHomeBrowseByPlacesQuery,
} from '../generated/graphql/get-home-browse-by-places.graphql'
import { Query } from '../generated/graphql/types'

// ------------------------------------------------------------------------------------------------------------
//
// NOTE:  Our hooks make use of useMemo() because they all return an object and if we return a new object
//        every time (even when the data doesn't change) any useEffects() that are dependent on this data
//        will re-run and potentially cause infinite render loops.
//
// ------------------------------------------------------------------------------------------------------------

export function useGetHomeBrowseByPlaces(
  variables: GetHomeBrowseByPlacesQueryVariables,
  options?: UseQueryOptions<GetHomeBrowseByPlacesQuery, ClientError, Query>
) {
  const { data, isFetching } = useGetHomeBrowseByPlacesQuery(new GraphQLClient(API_ENDPOINT), variables, {
    keepPreviousData: true,
    ...options,
  })

  return useMemo(() => {
    return {
      isFetching,
      data,
    }
  }, [data, isFetching])
}
