import { twMerge } from 'tailwind-merge'
import { BaseHighlightItemProps } from '../model'
import { getAddressCaption } from '@unreserved-frontend-v2/geolocation/utils/utils'

export const ADDRESS_CAPTION_GRID_AREA = 'addressCaption'

export const AddressCaption = ({ listing, style, className }: BaseHighlightItemProps) => {
  return (
    <div
      style={{ gridArea: ADDRESS_CAPTION_GRID_AREA, minWidth: 0, ...style }}
      className={twMerge('relative -top-[2px] truncate', className)}
    >
      {getAddressCaption(listing?.property.address)}
    </div>
  )
}
