import { twMerge } from 'tailwind-merge'
import { BaseHighlightItemProps } from '../model'
import { ImageWithShimmerOptimized } from '@unreserved-frontend-v2/ui/image-with-shimmer/image-with-shimmer-optimized'
import { getSimpleAddress } from '@unreserved-frontend-v2/geolocation/utils/utils'

export const THUMBNAIL_IMAGE_GRID_AREA = 'img'

export const ThumbnailImage = ({ listing, style, className, isRestricted }: BaseHighlightItemProps) => {
  const image = listing?.media?.[0]?.originUrl || ''

  return (
    <ImageWithShimmerOptimized
      style={{ gridArea: THUMBNAIL_IMAGE_GRID_AREA, ...style }}
      className={twMerge('h-[56px] w-[56px] flex-shrink-0 overflow-hidden rounded', className)}
      width={56}
      height={56}
      src={image}
      isRestricted={isRestricted}
      alt={getSimpleAddress(listing?.property.address)}
    />
  )
}
