import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import set from 'lodash/set'
import { useMemo, useEffect } from 'react'
import { UseQueryOptions, useQueryClient } from '@tanstack/react-query'

import { HAVE_WINDOW } from '@unreserved-frontend-v2/utils/window'

/**
 * TODO:[V2-673] Temporary until we upgrade to Next13's page level solutions, potentially introduce Zustand
 *
 * NOTE: This should NEVER be used/set/updated on the server, and should only be used through `useEffect`
 */
// Variables that were loaded with SSR
export const SSRCache: Record<
  string,
  | {
      variables: unknown
      cachedData?: Record<string, unknown> | null
    }
  | undefined
> = {}

export const useSSRBatchedData = <VariablesType, ResultType>(
  /** The alias/key(s) under which the data lives when returned (e.g. data.comparables with 'comparables' being the dataKey) */
  dataKeyEntries: string | string[],
  /** Variables used to both make the request and index the SSRCache with */
  variables: VariablesType,
  /** The hooks used to get new data if a cache does not exist for it */
  useDataMethod: unknown,
  /** Options supplied to the `useDataMethod` hook */
  options?: UseQueryOptions,
  /** An array of batched queries that we try to get the cached data from in terms of priority  */
  batchCacheKeys = ['GetListings']
) => {
  // Determining whether or not to use cached data
  const queryClient = useQueryClient()
  const queryCacheInfo = useMemo(() => {
    return (
      // Go through each query cache key to find a match
      batchCacheKeys
        ?.map((key) => {
          // Get the first result available for each key
          return queryClient.getQueryCache().findAll([key])?.pop()
        })
        // Filter out any non-results and select the first available result
        .filter(Boolean)?.[0]
    )
  }, [batchCacheKeys, queryClient])

  // The string key that we reference this set of data by
  const dataKey = useMemo(() => {
    return [dataKeyEntries].flat().join('-')
  }, [dataKeyEntries])

  // The collection of data that we
  const queryCacheData = useMemo(() => {
    return [dataKeyEntries].flat().reduce((acc, key) => {
      const data = get(queryCacheInfo?.state.data, key)
      if (data) {
        acc = acc || {}
        set(acc, key, get(queryCacheInfo?.state.data, key))
      }
      return acc
    }, undefined as undefined | Record<string, unknown>)
  }, [dataKeyEntries, queryCacheInfo?.state.data])

  // Only set SSRCache once for the client-side after the first initial render
  useEffect(() => {
    if (!SSRCache[dataKey] && !!queryCacheData) {
      SSRCache[dataKey] = {
        variables,
        cachedData: queryCacheData,
      }
    }
  }, [dataKey, queryCacheData, variables])

  const cachedData = useMemo(() => {
    // Always return cached data for SSR-side render, or the first client-side render
    if (!HAVE_WINDOW || !SSRCache[dataKey]) {
      return queryCacheData
    }

    // If the variables are the same as SSR ones & queryCacheInfo has not been invalidated, use cached data
    return !queryCacheInfo?.state.isInvalidated && isEqual(variables, SSRCache[dataKey]?.variables)
      ? SSRCache[dataKey]?.cachedData
      : undefined
  }, [dataKey, queryCacheData, queryCacheInfo?.state.isInvalidated, variables])

  const { data, isFetching } = (
    useDataMethod as <Variables, Options, Result>(
      variables: Variables,
      options?: Options
    ) => { data: Result; isFetching: boolean }
  )(variables, {
    staleTime: 5000,
    ...options,
    // Only enabled if no cachedData conditions are met and not specifically disabled.
    enabled: !cachedData && options?.['enabled'] !== false,
  })

  return useMemo(() => {
    return {
      data:
        cachedData ||
        // always fall back to previously SSR cached data if fetching for the first time
        (isFetching && !data ? queryCacheData : data),
      isFetching: !cachedData && isFetching,
      queryVariables: variables,
    }
  }, [cachedData, data, isFetching, queryCacheData, variables]) as unknown as ResultType
}
