import { twMerge } from 'tailwind-merge'
import { BaseHighlightItemProps } from '../model'

export const ADDRESS_1_GRID_AREA = 'address1'

export const AddressLine1 = ({ listing, style, className, isRestricted }: BaseHighlightItemProps) => {
  return (
    <div
      style={{ gridArea: ADDRESS_1_GRID_AREA, minWidth: 0, ...style }}
      className={twMerge('relative -top-1 truncate font-bold', isRestricted ? 'select-none blur-sm' : null, className)}
    >
      {listing?.property.address.address1 || ''}
    </div>
  )
}
