import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

export enum PillVariants {
  DEFAULT = 'default',
}

export interface PillProps {
  children: ReactNode
  containerClassName?: string
  variant?: PillVariants
}

export const PILL_CLASSES = {
  [PillVariants.DEFAULT]:
    'bg-shades-600 ml-2 flex h-5 content-center items-center rounded-[25px] px-2 indent-px font-bold uppercase leading-[0px] tracking-widest text-white inline-flex text-[.57rem]',
}

export const Pill = ({ children, containerClassName, variant = PillVariants.DEFAULT }: PillProps) => {
  const classes = PILL_CLASSES[variant]

  return <div className={twMerge(classes, containerClassName)}>{children}</div>
}
