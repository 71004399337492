import { CSSProperties, HTMLProps, ReactNode } from 'react'
import { Maybe, ResidentialPropertyForSaleListing } from '@unreserved-frontend-v2/api/generated/graphql/types'
import { useRestrictedListing } from '../use-restricted-listing'
import { twMerge } from 'tailwind-merge'
import Link from 'next/link'
import { AddressCaption, ADDRESS_CAPTION_GRID_AREA } from './components/address-caption/address-caption'
import { AddressLine1, ADDRESS_1_GRID_AREA } from './components/address-line-1/address-line-1'
import { Price, PRICE_GRID_AREA } from './components/price/price'
import { PropertyDetails, PROPERTY_DETAILS_GRID_AREA } from './components/property-details/property-details'
import { ThumbnailImage, THUMBNAIL_IMAGE_GRID_AREA } from './components/thumbnail-image/thumbnail-image'
import { formatListingDetailsUrl } from '../../utils/routing/parse-format-details-slugged-values'

export interface ListingHighlightProps extends HTMLProps<ReactNode> {
  listing: Maybe<ResidentialPropertyForSaleListing> | undefined
  containerClassName?: string
  children?: ReactNode
  style?: CSSProperties
}

/**
 * Base version of ListingHighlight
 * To extend this component, create a new smart component that wraps and configures it
 * by passing down components through children and a custom grid config through props
 */
export function ListingHighlight({ listing: rawListing, containerClassName, children, style }: ListingHighlightProps) {
  const { data: listing, isRestricted } = useRestrictedListing(rawListing as ResidentialPropertyForSaleListing)
  const url = formatListingDetailsUrl(listing)

  /**
   * Grid configuration that controls element placement
   */
  const defaultStyle = {
    display: 'grid',
    gridTemplateColumns: '56px 1fr auto',
    columnGap: '18px',
    gridTemplateAreas: `"${THUMBNAIL_IMAGE_GRID_AREA} ${ADDRESS_1_GRID_AREA} ${PRICE_GRID_AREA}"
                        "${THUMBNAIL_IMAGE_GRID_AREA} ${ADDRESS_CAPTION_GRID_AREA} ${ADDRESS_CAPTION_GRID_AREA}"
                        "${THUMBNAIL_IMAGE_GRID_AREA} ${PROPERTY_DETAILS_GRID_AREA} ${PROPERTY_DETAILS_GRID_AREA}"
                       `,
    ...style,
  }

  const template = !children ? (
    <>
      <ThumbnailImage listing={listing} isRestricted={isRestricted} />
      <AddressLine1 listing={listing} isRestricted={isRestricted} />
      <AddressCaption listing={listing} />
      <Price listing={listing} isRestricted={isRestricted} />
      <PropertyDetails listing={listing} />
    </>
  ) : (
    children
  )

  return !isRestricted ? (
    <Link href={url} style={defaultStyle} className={twMerge(containerClassName)}>
      {template}
    </Link>
  ) : (
    <div style={defaultStyle} className={twMerge(containerClassName)}>
      {template}
    </div>
  )
}

export default ListingHighlight
