import { twMerge } from 'tailwind-merge'
import { BaseHighlightItemProps } from '../model'
import { getParsedPriceValues } from '../../../../utils/formatters/get-parsed-price-values'

export const PRICE_GRID_AREA = 'price'

export const Price = ({ listing, style, className, isRestricted }: BaseHighlightItemProps) => {
  const { price, listPrice, isSaleDifferentFromList } = getParsedPriceValues(listing, isRestricted)

  return (
    <div style={{ gridArea: PRICE_GRID_AREA, minWidth: 0, ...style }} className={twMerge('text-right', className)}>
      <div className="relative -top-1 font-bold">{price}</div>
      {isSaleDifferentFromList ? <div className="relative -top-[2px] line-through">{listPrice}</div> : null}
    </div>
  )
}
