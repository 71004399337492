import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useCallback, useContext } from 'react'

import {
  BasicCountFacet,
  ResidentialPropertyForSaleListing,
  ResidentialPropertyListingConnection,
} from '@unreserved-frontend-v2/api/generated/graphql/types'
import {
  GeosearchFilter,
  GeoSearchVariant,
} from '@unreserved-frontend-v2/geolocation/components/geosearch-filter/geosearch-filter'
import { ListingsStackCard } from '@unreserved-frontend-v2/modules/listings/components/listings-stack-card/listings-stack-card'
import { SavingsWidgetLite } from '@unreserved-frontend-v2/modules/listings/components/savings-widget/savings-widget-lite'
import {
  LISTING_STATUS_TAB_LINK_CONFIG,
  STATUS_TAB_LINK_IDS,
} from '@unreserved-frontend-v2/modules/listings/utils/listing-status-tab-link-config'
import { UserContext } from '@unreserved-frontend-v2/modules/users/components/user-info-provider/user-context'
import { DesktopWidth } from '@unreserved-frontend-v2/ui/desktop-width/desktop-width'
import { FilterChangeEvent } from '@unreserved-frontend-v2/ui/schema-table/models'
import { useTailwindConfig } from '@unreserved-frontend-v2/ui/tailwind/use-tailwind-config'

import { getGeoUrl } from './utils/get-geo-url'

export interface HeroSectionDesktopProps {
  recentlyListed?: ResidentialPropertyListingConnection
  recentlySold?: ResidentialPropertyListingConnection
  listingsTabsFacets: BasicCountFacet[]
}

export const HeroSectionDesktop = ({ recentlyListed, recentlySold, listingsTabsFacets }: HeroSectionDesktopProps) => {
  const { push } = useRouter()
  const { t } = useTranslation('listings')
  const { hasToken } = useContext(UserContext)
  const { shades, primary } = useTailwindConfig()

  const geoChange = useCallback(
    (event: FilterChangeEvent | FilterChangeEvent[]) => {
      const destinationUrl = getGeoUrl(event as FilterChangeEvent[])
      if (destinationUrl) {
        push(destinationUrl)
      }
    },
    [push]
  )

  return (
    <>
      <div className="bg-primary">
        <DesktopWidth>
          <GeosearchFilter
            forMobile={false}
            isClearable={true}
            location={null}
            variant={GeoSearchVariant.GREEN}
            onChange={geoChange}
            containerClasses="w-full"
            dropdownContainerClassName="w-[480px]"
          />
          <div className="mt-8 grid grid-cols-12 gap-x-8 pb-5">
            {LISTING_STATUS_TAB_LINK_CONFIG.map((link) => {
              const listings = link.id === 'forSale' ? recentlyListed : recentlySold
              const facet = listingsTabsFacets.find((facet) => facet.id === link.id)
              return (
                <ListingsStackCard
                  isRestricted={(!hasToken && link.id === STATUS_TAB_LINK_IDS.SOLD) || undefined}
                  key={link.id}
                  testid={link.id}
                  containerClassName="col-span-4"
                  title={t(link.key)}
                  subTitle={t(link.subTitle)}
                  label={t(link.labelKey)}
                  count={facet?.count}
                  href={link.href}
                  listings={
                    listings?.edges?.map((edge) => edge?.node).slice(0, 3) as ResidentialPropertyForSaleListing[]
                  }
                />
              )
            })}
            <SavingsWidgetLite containerClassName="col-span-4" />
          </div>
        </DesktopWidth>
      </div>
      <div
        style={{ background: `linear-gradient(to bottom, ${primary[500]} 50%, ${shades[25]} 50%)` }}
        className="h-[77px]"
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src="/static/images/city-line.svg" alt="city-line" className="h-[77px] w-full" height={77} width={430} />
      </div>
    </>
  )
}
