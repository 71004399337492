import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBath, faBed, faRulerTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import dynamic from 'next/dynamic'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { SquareFootage } from '@unreserved-frontend-v2/api/generated/graphql/types'

import { BaseHighlightItemProps } from '../model'
import { AugmentedSquareFootageInfo } from '../../../augmented-square-footage-info/augmented-square-footage-info'

export const PROPERTY_DETAILS_GRID_AREA = 'propertyDetails'

const FontAwesomeIconBed: React.ComponentType<FontAwesomeIconProps> = dynamic(
  () => import('@fortawesome/react-fontawesome').then((pkg) => pkg.FontAwesomeIcon),
  {
    ssr: false,
    loading: () => <div className="mr-2 h-3 w-[15px]" />,
  }
)

const FontAwesomeIcon: React.ComponentType<FontAwesomeIconProps> = dynamic(
  () => import('@fortawesome/react-fontawesome').then((pkg) => pkg.FontAwesomeIcon),
  {
    ssr: false,
    loading: () => <div className="mr-2 h-3 w-3" />,
  }
)

export const PropertyDetails = ({ listing, style, className }: BaseHighlightItemProps) => {
  const { beds, baths, squareFootage } = listing?.propertyDetails || { propertyDetails: {} }
  const sqFt = squareFootage?.approximateSquareFootage || squareFootage?.derivedSquareFootage

  const sqFtLabel = [squareFootage?.approximateSquareFootage, squareFootage?.derivedSquareFootage].some(Boolean) ? (
    <AugmentedSquareFootageInfo squareFootage={squareFootage as SquareFootage} variant="mobile-listings-header" />
  ) : null

  const items: {
    key: string
    value?: string | number | null
    append?: ReactNode
    icon: IconProp
    iconHeight: number
    iconWidth: number
  }[] = [
    { key: 'beds', value: beds, icon: faBed, iconHeight: 12, iconWidth: 15 },
    { key: 'baths', value: baths, icon: faBath, iconHeight: 12, iconWidth: 12 },
    { key: 'sqFt', value: sqFt, append: sqFtLabel, icon: faRulerTriangle, iconHeight: 12, iconWidth: 12 },
  ].filter((item) => item.value != null)

  return (
    <ul
      style={{ gridArea: PROPERTY_DETAILS_GRID_AREA, ...style }}
      className={twMerge('flex space-x-5 truncate', className)}
    >
      {items.map((item) => {
        const FontAwesomeIconComponent = item.key === 'beds' ? FontAwesomeIconBed : FontAwesomeIcon

        return (
          <li key={item.key} className="flex items-center space-x-2 leading-[20px]">
            <FontAwesomeIconComponent
              icon={item.icon}
              width={item.iconWidth}
              height={item.iconHeight}
              className="pr-2 text-sm text-shades-600"
            />
            {item.value}
            {item.append}
          </li>
        )
      })}
    </ul>
  )
}
